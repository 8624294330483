export const products = [
    {
        id: 1,
        name: "Super Backpack",
        price: 129.99,
        description: "Torem ipsum dolor sit amet, consectetur adipisicing elitsed do eiusmo tempor incididunt ut labore et dolore magna",
        image: "/images/products/bag1.png"
    },
    {
        id: 2,
        name: "New Hip",
        price: 199.99,
        description: "Torem ipsum dolor sit amet, consectetur adipisicing elitsed do eiusmo tempor incididunt ut labore et dolore magna",
        image: "/images/products/bag2.png"
    },
    {
        id: 3,
        name: "Elite Series",
        price: 189.99,
        description: "Torem ipsum dolor sit amet, consectetur adipisicing elitsed do eiusmo tempor incididunt ut labore et dolore magna",
        image: "/images/products/bag3.png"
    },
    {
        id: 4,
        name: "Casual",
        price: 129.99,
        description: "Torem ipsum dolor sit amet, consectetur adipisicing elitsed do eiusmo tempor incididunt ut labore et dolore magna",
        image: "/images/products/bag4.png"
    },
    {
        id: 5,
        name: "Best Tote",
        price: 399.99,
        description: "Torem ipsum dolor sit amet, consectetur adipisicing elitsed do eiusmo tempor incididunt ut labore et dolore magna",
        image: "/images/products/bag5.png"
    },
    {
        id: 6,
        name: "Charming Series",
        price: 689.99,
        description: "Torem ipsum dolor sit amet, consectetur adipisicing elitsed do eiusmo tempor incididunt ut labore et dolore magna",
        image: "/images/products/bag6.png"
    },
    {
        id: 7,
        name: "Elite Series",
        price: 455.89,
        description: "Torem ipsum dolor sit amet, consectetur adipisicing elitsed do eiusmo tempor incididunt ut labore et dolore magna",
        image: "/images/products/product7.jpg"
    },
    {
        id: 8,
        name: "Best Tote",
        price: 106.90,
        description: "Torem ipsum dolor sit amet, consectetur adipisicing elitsed do eiusmo tempor incididunt ut labore et dolore magna",
        image: "/images/products/product2.jpeg"
    },
    {
        id: 9,
        name: "Casual",
        price: 629.89,
        description: "Torem ipsum dolor sit amet, consectetur adipisicing elitsed do eiusmo tempor incididunt ut labore et dolore magna",
        image: "/images/products/product55.jpg"
    }
]