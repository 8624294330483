import "./App.css";
import { useEffect } from "react";
import { ThemeProvider } from "@mui/system";
import theme from "./styles/theme";
import { Container, Box, Typography, Stack } from "@mui/material";
import Appbar from "./components/appbar";
import Banner from "./components/banner";
import Promotions from "./components/promotions";
import Products from "./components/products";
import Footer from "./components/footer";
import AppDrawer from "./components/drawer";
import { UIProvider } from "./context/ui";
import SearchBox from "./components/search";
import Cart from "./components/cart";


function App() {
  useEffect(()=>{
    document.title="React Material UI-Website";
  },[]);

  return (
    <ThemeProvider theme={theme}>
      <Container
      maxWidth='xl'
      sx={{
        background:"#fff",
      }}
      >
        <Stack>
        <UIProvider>
        <Appbar />
        <Banner />
        <Promotions/>
        <SearchBox/>
        <Box display="flex" justifyContent={"center"} sx={{p:4}}>
          <Typography variant="h4">Our Products</Typography>
        </Box>
        <Products />
        <Footer />
        <AppDrawer />
        <Cart />
        </UIProvider>
        </Stack>
        
      </Container>
      
    </ThemeProvider>
  );
}

export default App;
